<template>
	<div class="CommodityAddressCreate">
		<w-navTab titleText="添加收货地址"></w-navTab>
		<div class="CommodityAddressCreate-body">
			<div class="address-container">
				<div class="address-input-item">
					<input
						type="text"
						class="address-input"
						v-model="name"
						placeholder="请输入姓名"
					/>
				</div>
				<div class="address-input-item">
					<input
						type="number"
						class="address-input"
						v-model="telephone"
						placeholder="请输入电话"
					/>
				</div>
				<div class="address-input-item" @click="show = !show">
					<input
						type="text"
						class="address-input"
						v-model="address"
						readonly
						placeholder="请选择地址"
					/>
				</div>
				<div class="address-input-item">
					<input
						type="text"
						class="address-input"
						v-model="addressDetail"
						placeholder="请输入详细地址"
					/>
				</div>
			</div>
			<div class="footer-button">
				<van-button round block type="info" @click="onSubmit">保存</van-button>
			</div>
			<van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
				<van-area title="标题" :area-list="areaList" :value="value" @cancel="show = !show" @confirm="onClickConfirm" />
			</van-popup>
		</div>
		
	</div>
</template>

<script>
import WTitle from '@/components/Title';
import Commodity from '@/api/commodity';
import VantVendor from '@/vendor/vant';
import ValidatorVendor from '@/vendor/validator';
import { areaList } from '@vant/area-data';

export default {
	name: 'CommodityAddressCreate',
	data() {
		return {
			show: false,
			name: '',
			telephone: '',
			provinceId: '',
			cityId: '',
			areaId: '',
			addressDetail: '',
			address: '',
			value: '',
			areaList
		};
	},
	created() {
		this.validator = new ValidatorVendor({
			rules: {
				name: {
					required: true
				},
				telephone: {
					required: true,
					mobile: true
				},
				area_id: {
					required: true
				},
				detail: {
					required: true
				}
			},
			messages: {
				name: {
					required: '姓名不能为空'
				},
				telephone: {
					mobile: '手机号格式错误',
					required: '手机号不能为空'
				},
				area_id: {
					required: '请选择地址'
				},
				detail: {
					required: '详细地址不能为空'
				}
			}
		});
	},
	methods: {
		onClickConfirm(key){
			console.log(key[2].code)
			this.value = key[2].code
			this.show = !this.show
			this.address = key[0].name + '-' + key[1].name + '-' + key[2].name
			this.provinceId = key[0].code
			this.cityId = key[1].code
			this.areaId = key[2].code
		},
		onSubmit() {
			let params = {
				name: this.name,
				telephone: this.telephone,
				province_id: this.provinceId,
				city_id: this.cityId,
				area_id: this.areaId,
				detail: this.addressDetail,
			};

			if (!this.validator.checkData(params)) {
				return;
			}
			
			Commodity.productAddressCreate(params)
				.then(result => {
					VantVendor.Dialog({ title: '提示', message: '添加成功' }).then(() =>
						this.$router.back()
					);
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
	},
	components: {
		WTitle
	}
};
</script>

<style scoped lang="less">
.CommodityAddressCreate {
	height: 100vh;

	.CommodityAddressCreate-body{
		position: relative;
		padding: 16px 10px;
		box-sizing: border-box;
		.address-container {
			width: 100%;
			box-sizing: border-box;
		
			.address-input-tips {
				font-size: 14px;
				color: #999;
				margin-bottom: 20px;
				text-align: center;
			}
		
			.address-input-item {
				margin-bottom: 12px;
		
				.address-input {
					display: block;
					width: 100%;
					border: none;
					background-color: #fff;
					padding: 10px;
					line-height: 20px;
					border-radius: 6px;
					font-size: 16px;
					box-sizing: border-box;
				}
			}
		
		}
		.footer-button {
			position: fixed;
			bottom: 20px;
			left: 0;
			width: 100%;
			padding: 0 10px;
			box-sizing: border-box;
			.van-button {
				width: 100%;
				font-size: 18px;
			}
		}
	}
	
}
</style>
